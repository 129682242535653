import LogoHT from "../src/assets/HelenTran.svg";
import HT from "../src/assets/HT.svg";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

const NavBar = () => {
  return (
    <Wrapper>
      <WrapperNav to="/">
        <LogoNav src={LogoHT} />
        <LogoNavMobile src={HT} />
      </WrapperNav>
      <WrapperDetails>
        <Link to="/about">ABOUT</Link>
        <Link to="/portfolio">PORTFOLIO</Link>
      </WrapperDetails>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 50px;
  border-bottom: solid 1px #222222;
  @media (max-width: 900px) {
    height: 0px;
    display: flex;
    align-items: center;
  }
`;
const LogoNav = styled.img`
  margin-left: 50px;
  @media (max-width: 900px) {
    display: none;
  }
`;
const LogoNavMobile = styled.img`
  display: none;
  margin-left: 50px;
  @media (max-width: 900px) {
    display: flex;
    width: 80px;
    margin-left: 30px;
  }
`;
const WrapperDetails = styled.div`
  display: flex;
  margin-right: 50px;
  @media (max-width: 900px) {
    margin: 0px;
    margin-right: 30px;
  }
`;
const Link = styled(NavLink)`
  text-decoration: none;
  font-family: "roc-grotesk", sans-serif;
  font-size: 24px;
  color: #222222;
  margin-left: 50px;
  &:hover {
    color: #b73232;
    transition: 0.3s;
  }
  @media (max-width: 900px) {
    font-size: 16px;
    margin-left: 20px;
  }
`;
const WrapperNav = styled(NavLink)``;

export default NavBar;
