import NavBar from "../NavBar";
import styled from "styled-components";
import ContactBar from "../ContactBar";
import Rachel from "../assets/eechee/Rachel-Zhang.jpeg";
import EecheeHeader from "../assets/eechee/EecheeHeader.png";
import SiteMap from "../assets/eechee/SiteMap.png";
import Designs from "../assets/eechee/FinalDesigns.png";
import homepage from "../assets/eechee/homepage.png";
import login from "../assets/eechee/signup-login.png";
import homelogin from "../assets/eechee/home-signedin.png";
import projects from "../assets/eechee/projects.png";
import project from "../assets/eechee/project.png";
import addTask from "../assets/eechee/addTasks.png";
import profile from "../assets/eechee/profile.png";
import EecheeLogo from "../assets/eechee/Eechee-Title.png";
import ColorGroup from "../assets/eechee/ColorGroup.png";
import AntiqueOlive from "../assets/eechee/AntiqueOlive.png";
import RocGrotesk from "../assets/eechee/RocGrotesk.png";
import LoadingScreen from "../assets/eechee/LoadingScreen.gif";

const Eechee = () => {
  return (
    <Wrapper>
      <WrapperHeader>
        <NavBar />
        <ImgWrapper>
          <HeaderImg src={EecheeHeader} />
          <TitleWrapper>
            <EecheeImg src={EecheeLogo} />
          </TitleWrapper>
        </ImgWrapper>
      </WrapperHeader>
      <Section>
        <SubTitle>Overview</SubTitle>
        <DetailText>
          In mandarin, eechee means together, which is the name of this website.
          Eechee is a web based, work managing platform that help make the
          workflow more efficient. Often times, people have issues managing
          their work without tools and it can become stressful.
          <br />
          <br />
          The goal of Eechee is to provide a fun environment that helps ease the
          stress from serious workload and create a serene pleasant workflow.
        </DetailText>
        <WrapperDetails>
          <Container>
            <SmallTitle>My Role</SmallTitle>
            <DetailText style={{ marginTop: "10px" }}>
              UI DESIGN, WEB DESIGN, PROTOTYPE, FULL STACK DEVELOPER
            </DetailText>
          </Container>
          <Container>
            <SmallTitle>Tools</SmallTitle>
            <DetailText style={{ marginTop: "10px" }}>
              FIGMA, ADOBE PHOTOSHOP & ADOBE ILLUSTRATOR
            </DetailText>
          </Container>
          <Container>
            <SmallTitle>Duration</SmallTitle>
            <DetailText style={{ marginTop: "10px" }}>
              APRIL 2022 - MAY 2022
            </DetailText>
          </Container>
        </WrapperDetails>
      </Section>

      <SectionGray>
        <Left>
          <SubTitle>User Persona</SubTitle>
          <DetailText>
            Meet Rachel Zhang, 21 year old student and freelance designer.
            Rachel has many due dates and meeting because of her education and
            job. She is always multitasking and need to rely on her note book
            agenda to keep up to date with her work. As a designer and a student
            she loves to have fun but work/ school is taking away from that.
          </DetailText>
          <SmallTitle style={{ marginBottom: "10px" }}>Pain Points</SmallTitle>
          <Li>A desire for a fun enviroment</Li>
          <Li>Falling behind in her tasks</Li>
          <Li>Needs to management her time accordingly</Li>
          <SmallTitle style={{ marginBottom: "10px", marginTop: "50px" }}>
            Goals
          </SmallTitle>
          <Li>A tool to help her time management</Li>
          <Li>Plesant and fun enviroment to organize tasks</Li>
          <Li>Get ahead of her work without the stress</Li>
        </Left>
        <Right>
          <Persona src={Rachel} />
        </Right>
      </SectionGray>

      <Section>
        <SubTitle>Site Maps</SubTitle>
        <SitemapImg src={SiteMap} />
      </Section>

      <Section>
        <SubTitle>Wireframes</SubTitle>
        <WireframeWrapper>
          <Container>
            <Wireframe src={homepage} />
            <DetailText>Home Page</DetailText>
          </Container>
          <Container>
            <Wireframe src={login} />
            <DetailText>Sign In & Sign Up</DetailText>
          </Container>
          <Container>
            <Wireframe src={homelogin} />
            <DetailText>Home Page - Sign In</DetailText>
          </Container>
          <Container>
            <Wireframe src={projects} />
            <DetailText>Projects</DetailText>
          </Container>
          <Container>
            <Wireframe src={project} />
            <DetailText>Project</DetailText>
          </Container>
          <Container>
            <Wireframe src={addTask} />
            <DetailText>Add Task</DetailText>
          </Container>
          <Container>
            <Wireframe src={profile} />
            <DetailText>Profile</DetailText>
          </Container>
        </WireframeWrapper>
      </Section>
      <Section>
        <SubTitle>BRANDING / DESIGN SOLUTION</SubTitle>
        <ContainerRow>
          <ContainerLeft>
            <SmallTitle>Logo</SmallTitle>
            <LogoBranding src={EecheeLogo} style={{ marginTop: "20px" }} />
          </ContainerLeft>
          <ContainerRight>
            <DetailText>
              Antique Oliva was selected as the font for the logo, because of
              it’s fun and pleasant rounded shapes which makes the vibe more
              inviting. The letter “e” was designed to be face to face to infere
              the teamwork and the interaction between members. There are two
              variation of the logo, a longer version and a shorten icon logo.
            </DetailText>
          </ContainerRight>
        </ContainerRow>
        <ContainerRow>
          <ContainerLeft>
            <SmallTitle>Color</SmallTitle>
            <LogoBranding src={ColorGroup} style={{ marginTop: "20px" }} />
          </ContainerLeft>
          <ContainerRight>
            <DetailText>
              The cream color was chosen as a background, again we want to
              concentrate on making the app more inviting and pleasant to use. A
              harsh pure would’ve taken away from the “comfort and safe” space
              of the website. A fun turquoise/blue color is picked for the fun
              element. It adds color to the website, compared to most project
              management project which uses black for the body text. The blue
              pigments of the color brings a sense of peacefulness paired with
              the cream color, which will help ease the stress.{" "}
            </DetailText>
          </ContainerRight>
        </ContainerRow>
        <ContainerRow style={{ border: "none" }}>
          <ContainerLeft>
            <SmallTitle>Typography</SmallTitle>
            <LogoBranding src={AntiqueOlive} style={{ marginTop: "20px" }} />
          </ContainerLeft>
          <ContainerRight>
            <DetailText>
              Antique Olive was chosen as the main header and title font. It’s
              rounded and quirky features makes the typography approachable and
              amusant.
            </DetailText>
          </ContainerRight>
        </ContainerRow>
        <ContainerRow style={{ margin: "0", border: "none" }}>
          <ContainerLeft>
            <SmallTitle style={{ color: "transparent" }}> dsds</SmallTitle>
            <LogoBranding src={RocGrotesk} style={{ marginTop: "20px" }} />
          </ContainerLeft>
          <ContainerRight>
            <DetailText>
              Roc Grotesk is chosen as a body text because it pairs well with
              Antique Olive. They both have the similar feature where the stroke
              thins out in to the stem of the letters. It also possesses the
              round feature. Despite the similarity in Antique Olive and Roc
              Grotesk, Roc Grotesk is more legible as a body text.
            </DetailText>
          </ContainerRight>
        </ContainerRow>
      </Section>
      <Section>
        <SubTitle>ANIMATION LOADING SCREEN</SubTitle>
        <Loading src={LoadingScreen} />
      </Section>
      <Section>
        <SubTitle>FINAL DESIGNS</SubTitle>
        <FinalImg src={Designs} />
      </Section>
      <LinkDiv>
        <Link
          href="https://www.figma.com/proto/fIgj8SJFLIOWiBznHf0AXj/Eechee---Prototype?page-id=3%3A4&node-id=132%3A25&viewport=940%2C-234%2C0.2&scaling=min-zoom&starting-point-node-id=132%3A25"
          target="_blank"
        >
          CLICK HERE TO VIEW THE PROTOTYPE
        </Link>
      </LinkDiv>
      <WrapperLeftCorner>
        <ContactBar />
      </WrapperLeftCorner>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  margin: auto;
  padding-bottom: 50px;
`;

const WrapperHeader = styled.div``;

const EecheeImg = styled.img`
  background-color: transparent;
  /* mobile css */
  @media (max-width: 1300px) {
    width: 45%;
  }
  @media (max-width: 500px) {
    width: 30%;
  }
`;

const Section = styled.div`
  margin-top: 100px;
  margin-right: 300px;
  margin-left: 300px;
  margin-bottom: 50px;
  /* mobile css */
  @media (max-width: 1300px) {
    margin-top: 50px;
    margin-right: 60px;
    margin-left: 60px;
    margin-bottom: 50px;
  }
  @media (max-width: 500px) {
    margin-top: 50px;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 50px;
  }
`;

const SectionGray = styled.div`
  display: flex;
  padding-top: 100px;
  padding-right: 300px;
  padding-left: 300px;
  background: #ebebeb;
  @media (max-width: 1300px) {
    flex-direction: column;
    padding-top: 50px;
    padding-right: 60px;
    padding-left: 60px;
  }
  @media (max-width: 500px) {
    flex-direction: column;
    padding-top: 50px;
    padding-right: 10px;
    padding-left: 10px;
  }
`;

const Left = styled.div`
  background: transparent;
  width: 50%;
  margin-bottom: 100px;
  @media (max-width: 1300px) {
    width: 100%;
  }
`;

const Right = styled.div`
  display: flex;
  justify-content: center;
  background: transparent;
  width: 50%;
  margin-bottom: 100px;
  @media (max-width: 1300px) {
    width: 100%;
  }
`;

const TitleWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50px;
  transform: translate(0, -50%);
  background: transparent;
  /* mobile css */
  @media (max-width: 1300px) {
    left: 30px;
  }
`;

const HeaderImg = styled.img`
  width: 100%;
`;

const Li = styled.li`
  font-family: "roc-grotesk", sans-serif;
  color: #222222;
  font-size: 23px;
  line-height: 1.4;
  background: transparent;
  /* mobile css */
  @media (max-width: 500px) {
    font-size: 16px;
    padding: 0 30px 0 30px;
  }
`;

const Wireframe = styled.img`
  margin-right: 50px;
  @media (max-width: 1300px) {
    width: 300px;
  }
  @media (max-width: 500px) {
    margin-left: 30px;
    width: 300px;
  }
`;
const WireframeWrapper = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media (max-width: 500px) {
    margin-top: 30px;
  }
`;
const ImgWrapper = styled.div`
  position: relative;
`;

const WrapperDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 350px;
`;

const DetailText = styled.p`
  margin-top: 30px;
  font-family: "roc-grotesk", sans-serif;
  color: #222222;
  font-size: 23px;
  line-height: 1.2;
  margin-bottom: 50px;
  background: transparent;
  line-height: 1.4;
  /* mobile css */
  @media (max-width: 500px) {
    margin-top: 20px;
    font-size: 16px;
    padding: 0 30px 0 30px;
    line-height: 1.4;
  }
`;

const Persona = styled.img`
  width: auto;
  height: 400px;
  border-radius: 10%;
  @media (max-width: 1300px) {
    width: auto;
    height: 500px;
  }
  @media (max-width: 500px) {
    width: auto;
    height: 300px;
  }
`;
const LinkDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Link = styled.a`
  font-family: "Syne", sans-serif;
  color: #222222;
  font-size: 32px;
  font-weight: bold;
  margin-top: 100px;
  margin-bottom: 100px;
  cursor: pointer;
  @media (max-width: 1300px) {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 80px;
    font-size: 20px;
  }
  @media (max-width: 500px) {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 80px;
    font-size: 20px;
  }
`;

const SubTitle = styled.h3`
  display: flex;
  align-items: center;
  font-family: "Syne", sans-serif;
  color: #222222;
  font-size: 32px;
  font-weight: bold;
  background: transparent;
  text-transform: uppercase;
  @media (max-width: 500px) {
    font-size: 22px;
    padding-left: 30px;
  }
`;

const SmallTitle = styled.h3`
  display: flex;
  align-items: center;
  font-family: "Syne", sans-serif;
  color: #222222;
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
  background: transparent;
  @media (max-width: 500px) {
    font-size: 22px;
    padding-left: 30px;
  }
`;

const WrapperLeftCorner = styled.div`
  display: flex;
  @media (max-width: 500px) {
    display: flex;
    padding-left: 30px;
    padding-top: 30px;
  }
`;
const SitemapImg = styled.img`
  width: 100%;
  margin-top: 50px;
  @media (max-width: 500px) {
    margin-left: 30px;
    width: 83%;
  }
`;
const FinalImg = styled.img`
  width: 100%;
  margin-top: 50px;
  @media (max-width: 500px) {
    margin-top: 20px;
    margin-left: 30px;
    width: 83%;
  }
`;
const ContainerRow = styled.div`
  display: flex;
  border-bottom: 1px solid #222222;
  margin-top: 50px;
  @media (max-width: 1300px) {
    flex-direction: column;
  }
`;

const ContainerLeft = styled.div`
  width: 50%;
  @media (max-width: 1300px) {
    width: 100%;
  }
`;
const ContainerRight = styled.div`
  margin-top: 20px;
  width: 50%;
  @media (max-width: 1300px) {
    width: 100%;
  }
`;
const LogoBranding = styled.img`
  margin-top: 20px;
  width: 300px;
  @media (max-width: 500px) {
    margin-left: 30px;
  }
`;
const Loading = styled.img`
  width: 100%;
  margin-top: 50px;
  @media (max-width: 500px) {
    width: 85%;
    margin-left: 30px;
  }
`;
export default Eechee;
