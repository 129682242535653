import NavBar from "./NavBar";
import styled from "styled-components";
import ContactBar from "./ContactBar";
import { NavLink } from "react-router-dom";

const Portfolio = () => {
  return (
    <Wrapper>
      <NavBar />
      <WrapperTitle>
        <Title>PORTFOLIO</Title>
      </WrapperTitle>
      <WrapperPortfolio>
        <PortfolioContainer to="/design-portfolio">DESIGN</PortfolioContainer>
        <PortfolioContainer to="/dev-portfolio">DEVELOPER</PortfolioContainer>
      </WrapperPortfolio>
      <WrapperLeftCorner>
        <ContactBar />
      </WrapperLeftCorner>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  padding-bottom: 50px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 900px) {
    height: auto;
    padding-bottom: 10px;
  }
`;

const Title = styled.h1`
  font-size: 80px;
  font-family: "Syne", sans-serif;
  color: #222222;
  font-weight: 800;
  margin-left: 50px;
  @media (max-width: 900px) {
    font-size: 30px;
    margin-top: 30px;
    margin-left: 30px;
  }
`;
const WrapperPortfolio = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #222222;
  @media (max-width: 900px) {
    flex-direction: column;
    border: none;
    border-bottom: 1px solid #222222;
    margin-bottom: 20px;
  }
`;
const PortfolioContainer = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "syne", sans-serif;
  font-size: 35px;
  font-weight: 900;
  border-left: 1px solid #222222;
  width: 50%;
  height: 50vh;
  color: #222222;
  text-decoration: none;
  &:hover {
    background: #b73232;
    color: #fbfaf7;
    transition: 0.3s;
  }
  @media (max-width: 900px) {
    height: 34vh;
    width: 100%;
    border-top: 1px solid #222222;
    font-size: 16px;
  }
  @media (max-width: 500px) {
    height: 25.5vh;
    width: 100%;
    border-top: 1px solid #222222;
    font-size: 16px;
  }
`;
const WrapperTitle = styled.div`
  display: flex;
  align-items: center;
  height: 300px;
  @media (max-width: 900px) {
    height: auto;
    padding-bottom: 30px;
  }
`;
const WrapperLeftCorner = styled.div`
  display: flex;
  @media (max-width: 900px) {
    display: flex;
    position: absolute;
    bottom: 30px;
    padding-left: 30px;
  }
`;
export default Portfolio;
