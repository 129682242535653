import NavBar from "./NavBar";
import styled from "styled-components";
import ContactBar from "./ContactBar";
import eechee from "./assets/eechee-display.png";
import mackage from "./assets/mackage-display.png";
import split from "./assets/split-display.png";
import { NavLink } from "react-router-dom";
const DesignPortfolio = () => {
  return (
    <Wrapper>
      <WrapperHeader>
        <NavBar />
        <Title>UI/UX DESIGN</Title>
        <WrapperSkills>
          <SubTitle>SKILLS</SubTitle>
          <LanguageBox>FIGMA</LanguageBox>
          <LanguageBox>SKETCH</LanguageBox>
          <LanguageBox>ADOBE XD</LanguageBox>
          <LanguageBox>ADOBE CREATIVE SUITE</LanguageBox>
          <LanguageBox>HTML</LanguageBox>
          <LanguageBox>CSS</LanguageBox>
          <LanguageBox>JAVASCRIPT</LanguageBox>
          <LanguageBox>REACT.JS</LanguageBox>
        </WrapperSkills>
      </WrapperHeader>
      <ProjectsWrapper>
        <ProjectWrapper to="/eechee">
          <DisplayProject src={eechee} />
          <SubTitle>EECHEE</SubTitle>
          <Details>UI DESIGN/WEB DESIGN</Details>
        </ProjectWrapper>
        <ProjectWrapper to="/mackage">
          <DisplayProject src={mackage} />
          <SubTitle>MACKAGE</SubTitle>
          <Details>Homepage Web Design</Details>
        </ProjectWrapper>
        <ProjectWrapper to="/split">
          <DisplayProject src={split} />
          <SubTitle>SPLIT</SubTitle>
          <Details>UI DESIGN</Details>
        </ProjectWrapper>
      </ProjectsWrapper>
      <WrapperLeftCorner>
        <ContactBar />
      </WrapperLeftCorner>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  padding-bottom: 50px;
`;
const WrapperHeader = styled.div`
  height: 100vh;
  border-bottom: solid 1px #222222;
  @media (max-width: 1300px) {
    height: 78vh;
  }
  @media (max-width: 500px) {
    height: auto;
  }
`;
const Title = styled.h1`
  font-size: 80px;
  font-family: "Syne", sans-serif;
  color: #222222;
  font-weight: 800;
  margin-top: 50px;
  margin-left: 50px;
  @media (max-width: 900px) {
    font-size: 30px;
    margin-top: 30px;
    margin-left: 30px;
  }
`;
const WrapperSkills = styled.div`
  display: flex;
  align-content: center;
  margin-left: 50px;
  margin-right: 50px;
  position: absolute;
  bottom: 50px;
  @media (max-width: 900px) {
    position: static;
    overflow-x: auto;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 52vh;
    padding-left: 30px;
    padding-bottom: 30px;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`;

const SubTitle = styled.h3`
  display: flex;
  align-items: center;
  font-family: "Syne", sans-serif;
  color: #222222;
  font-size: 32px;
  font-weight: bold;
  @media (max-width: 900px) {
    font-size: 22px;
  }
`;
const LanguageBox = styled.div`
  display: flex;
  justify-content: center;
  white-space: nowrap;
  align-items: center;
  border: solid 1px #222222;
  border-radius: 25px;
  font-family: "roc-grotesk", sans-serif;
  text-transform: uppercase;
  font-size: 25px;
  padding: 10px 20px 10px 20px;
  margin-left: 50px;
  @media (max-width: 900px) {
    font-size: 16px;
    padding: 5px 15px 5px 15px;
    margin-left: 20px;
  }
`;

const WrapperLeftCorner = styled.div`
  display: flex;
  @media (max-width: 900px) {
    display: flex;
    padding-left: 30px;
    padding-top: 30px;
  }
`;
const ProjectsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  padding: 100px 0px 100px 0px;
  width: 80%;
  @media (max-width: 1300px) {
    flex-direction: column;
    padding: 0;
  }
`;
const ProjectWrapper = styled(NavLink)`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  justify-content: space-between;
  cursor: pointer;
  @media (max-width: 900px) {
    margin-top: 50px;
  }
`;
const DisplayProject = styled.img`
  width: 400px;
  border-radius: 10%;
  margin-bottom: 20px;
  @media (max-width: 900px) {
    width: 300px;
  }
`;
const Details = styled.p`
  font-family: "roc-grotesk", sans-serif;
  text-transform: uppercase;
  font-size: 25px;
  margin-top: 10px;
  color: #222222;
  @media (max-width: 900px) {
    font-size: 16px;
  }
`;
export default DesignPortfolio;
