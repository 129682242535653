import NavBar from "./NavBar";
import styled from "styled-components";
import Arrow from "./assets/ArrowLink.svg";
import ContactBar from "./ContactBar";

const About = () => {
  return (
    <PageWrapper>
      <NavBar />
      <Content>
        <WrapperLeft>
          <Title>ABOUT</Title>
          <DesignLink href="https://helentran.webflow.io/" target="_blank">
            <LinkDiv>
              VIEW GRAPHIC DESIGN PORTFOLIO
              <ArrowImg src={Arrow} />
            </LinkDiv>
          </DesignLink>
        </WrapperLeft>
        <WrapperRight>
          <HeaderText>
            I’m a full stack developper and a graphic designer, with a passion
            of merging code and design to create a digital experience.
          </HeaderText>
          <DetailText>
            With my previous experience in graphic design, and my passion for
            web development, I am able to use my technical skills to bring
            creative visions to life.
          </DetailText>
        </WrapperRight>
      </Content>
      <WrapperLeftCorner>
        <ContactBar />
      </WrapperLeftCorner>
    </PageWrapper>
  );
};
const PageWrapper = styled.div`
  height: 100%;
`;
const Content = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
  }
`;
const WrapperLeft = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-left: 30px;
  margin-right: 30px;

  @media (min-width: 900px) {
    width: 50%;
    margin: 50px 50px 0px 50px;
  }
`;
const DesignLink = styled.a`
  text-decoration: none;
`;
const WrapperRight = styled.div`
  /* mobile css */
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  margin-left: 30px;
  margin-right: 30px;

  /* desktop css */
  @media (min-width: 900px) {
    width: 50%;
    margin: 50px 50px 0px 50px;
  }
`;
const Title = styled.h1`
  font-size: 80px;
  font-family: "Syne", sans-serif;
  color: #222222;
  font-weight: 800;
  @media (max-width: 900px) {
    font-size: 30px;
  }
`;
const HeaderText = styled.h3`
  font-family: "roc-grotesk", sans-serif;
  color: #222222;
  font-weight: 400;
  font-size: 23px;
  line-height: 1.2;
  @media (max-width: 900px) {
    font-size: 14px;
  }
`;

const DetailText = styled.p`
  font-family: "roc-grotesk", sans-serif;
  color: #222222;
  font-size: 23px;
  position: fixed;
  bottom: 50px;
  line-height: 1.2;
  margin-right: 50px;
  @media (max-width: 900px) {
    font-size: 14px;
    margin-right: 30px;
    position: absolute;
    margin-bottom: 30px;
  }
`;

const LinkDiv = styled.div`
  display: flex;
  margin-top: 30px;
  align-items: center;
  font-family: "roc-grotesk", sans-serif;
  font-size: 22px;
  color: #222222;
  transition: 0.5s;
  &:hover {
    margin-left: 15px;
    transition: 0.5s;
  }
  @media (max-width: 900px) {
    font-size: 14px;
    margin-top: 10px;
  }
`;

const ArrowImg = styled.img`
  fill: #b73232;
  margin-left: 20px;
  width: 28px;
`;

const WrapperLeftCorner = styled.div`
  display: flex;
  position: fixed;
  bottom: 50px;
  @media (max-width: 900px) {
    display: flex;
    position: absolute;
    bottom: 30px;
    padding-left: 30px;
  }
`;
export default About;
