import NavBar from "./NavBar";
import styled from "styled-components";
import ContactBar from "./ContactBar";
import IndividualProject from "./IndividualProject";
import projects from "./data/projects.json";
const Portfolio = () => {
  return (
    <Wrapper>
      <WrapperHeader>
        <NavBar />
        <Title>DEVELOPER</Title>
        <WrapperSkills>
          <SubTitle>LANGUAGES</SubTitle>
          <LanguageBox>html5</LanguageBox>
          <LanguageBox>css</LanguageBox>
          <LanguageBox>javascript</LanguageBox>
          <LanguageBox>react.js</LanguageBox>
          <LanguageBox>node.js</LanguageBox>
          <LanguageBox>mongo.db</LanguageBox>
        </WrapperSkills>
      </WrapperHeader>
      {projects.map((project) => {
        const title = project.title;
        const details = project.details;
        const figma = project.figmaLink;
        const github = project.githubLink;
        const id = project.id;
        const imgProject = project.imgProject;
        const languages = project.languages;

        return (
          <div key={id}>
            <IndividualProject
              title={title}
              details={details}
              figma={figma}
              github={github}
              imgProject={imgProject}
              languages={languages}
            />
          </div>
        );
      })}
      <WrapperLeftCorner>
        <ContactBar />
      </WrapperLeftCorner>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  padding-bottom: 50px;
`;
const WrapperHeader = styled.div`
  height: 100vh;
  border-bottom: solid 1px #222222;
  @media (max-width: 1300px) {
    height: 78vh;
  }
  @media (max-width: 500px) {
    height: auto;
  }
`;
const Title = styled.h1`
  font-size: 80px;
  font-family: "Syne", sans-serif;
  color: #222222;
  font-weight: 800;
  margin-top: 50px;
  margin-left: 50px;
  @media (max-width: 900px) {
    font-size: 30px;
    margin-top: 30px;
    margin-left: 30px;
  }
`;
const WrapperSkills = styled.div`
  display: flex;
  align-content: center;
  margin-left: 50px;
  margin-right: 50px;
  position: absolute;
  bottom: 50px;
  @media (max-width: 900px) {
    position: static;
    overflow-x: auto;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 52vh;
    padding-bottom: 30px;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`;

const SubTitle = styled.h3`
  display: flex;
  align-items: center;
  font-family: "Syne", sans-serif;
  color: #222222;
  font-size: 32px;
  font-weight: bold;
  @media (max-width: 900px) {
    font-size: 22px;
    padding-left: 30px;
  }
`;
const LanguageBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px #222222;
  border-radius: 25px;
  font-family: "roc-grotesk", sans-serif;
  text-transform: uppercase;
  font-size: 25px;
  padding: 10px 20px 10px 20px;
  margin-left: 50px;
  @media (max-width: 900px) {
    font-size: 16px;
    padding: 5px 15px 5px 15px;
    margin-left: 20px;
  }
`;

const WrapperLeftCorner = styled.div`
  display: flex;
  @media (max-width: 900px) {
    display: flex;
    padding-left: 30px;
    padding-top: 30px;
  }
`;
export default Portfolio;
