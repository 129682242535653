import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";
import GlobalStyles from "./GlobalStyles";
import HomePage from "./Homepage";
import DevPortfolio from "./DevPortfolio";
import DesignPortfolio from "./DesignPortfolio";
import Portfolio from "./Portfolio";
import About from "./About";
import Eechee from "./ProjectPage/Eechee";
import Mackage from "./ProjectPage/Mackage";
import Split from "./ProjectPage/Split";

const App = () => {
  return (
    <HashRouter>
      <GlobalStyles />
      <div>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/dev-portfolio" element={<DevPortfolio />} />
          <Route path="/design-portfolio" element={<DesignPortfolio />} />
          <Route path="/about" element={<About />} />
          <Route path="/eechee" element={<Eechee />} />
          <Route path="/mackage" element={<Mackage />} />
          <Route path="/split" element={<Split />} />
        </Routes>
      </div>
    </HashRouter>
  );
};

export default App;
