import styled from "styled-components";
import { NavLink } from "react-router-dom";
import ContactBar from "./ContactBar";
import LogoHome from "../src/assets/Logo-Home.svg";

const HomePage = () => {
  return (
    <Wrapper>
      <Logo src={LogoHome} alt="logoHome" />
      <AboutLink to="/about">ABOUT</AboutLink>
      {/* Mobile Version */}
      <WrapperMobile>
        <AboutLinkMobile to="/about">ABOUT</AboutLinkMobile>
        <PortfolioLinkMobile to="/portfolio">PORTFOLIO</PortfolioLinkMobile>
      </WrapperMobile>
      {/* Mobile Version */}
      <WrapperLeftCorner>
        <ContactBar />
        <PortfolioLink to="/portfolio">PORTFOLIO</PortfolioLink>
      </WrapperLeftCorner>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 0;
  background: #fbfaf7;
  height: 100vh;
  @media (max-width: 900px) {
    display: flex;
    justify-content: center;
    height: 100%;
  }
`;

const AboutLink = styled(NavLink)`
  font-size: 22px;
  text-decoration: none;
  font-family: "syne", sans-serif;
  position: fixed;
  top: 50px;
  right: 50px;
  font-weight: bold;
  color: #222222;
  &:hover {
    color: #b73232;
    transition: 0.3s;
  }
  @media (max-width: 900px) {
    display: none;
  }
`;
const PortfolioLink = styled(NavLink)`
  text-decoration: none;
  font-family: "syne", sans-serif;
  font-weight: 800;
  color: #222222;
  font-size: 74px;
  position: fixed;
  bottom: 50px;
  right: 50px;
  &:hover {
    color: #b73232;
    transition: 0.3s;
  }
  @media (max-width: 900px) {
    display: none;
  }
`;

const WrapperLeftCorner = styled.div`
  /*  */
  display: flex;
  position: absolute;
  bottom: 50px;
  @media (min-width: 900px) {
    display: flex;
    align-items: center;
    bottom: 50px;
  }
`;

const Logo = styled.img`
  position: fixed;
  top: 50px;
  margin-left: 50px;
  width: 750px;

  /* mobile */
  @media (max-width: 900px) {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    width: 300px;
  }
`;

// MOBILE ONLY
const AboutLinkMobile = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  text-decoration: none;
  font-family: "syne", sans-serif;
  font-weight: 800;
  color: #222222;
  margin-bottom: 10px;

  @media (min-width: 900px) {
    display: none;
  }
`;
const PortfolioLinkMobile = styled(NavLink)`
  /* mobile */
  text-decoration: none;
  font-family: "syne", sans-serif;
  font-weight: 800;
  color: #222222;
  font-size: 22px;
  &:hover {
    color: #b73232;
    transition: 0.3s;
  }
  /* desktop */
  @media (min-width: 900px) {
    display: none;
  }
`;
const WrapperMobile = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
`;
export default HomePage;
