import { useState } from "react";
import styled from "styled-components";
import { CopyToClipboard } from "react-copy-to-clipboard";

const ContactBar = () => {
  const [copied, setCopied] = useState(false);
  const clickedCopy = () => {
    setCopied(true);
  };
  return (
    <WrapperUl>
      <li>
        <Link href="https://github.com/helen-tran" target="_blank">
          GITHUB
        </Link>
      </li>
      <li>
        <Link
          href="https://www.linkedin.com/in/helen-tran-49982517b/"
          target="_blank"
        >
          LINKEDIN
        </Link>
      </li>
      <li>
        <CopyToClipboard text="tran.helen232@gmail.com">
          <EmailButton onClick={clickedCopy}>
            {copied ? (
              <div style={{ color: "#b73232" }}>EMAIL COPIED</div>
            ) : (
              <div>EMAIL</div>
            )}
          </EmailButton>
        </CopyToClipboard>
      </li>
    </WrapperUl>
  );
};
const WrapperUl = styled.ul`
  display: flex;
  align-items: center;
  margin-left: 50px;
  margin-top: 50px;
  @media (max-width: 900px) {
    margin: 0;
  }
`;
const Link = styled.a`
  text-decoration: none;
  font-family: "syne", sans-serif;
  font-size: 22px;
  color: #222222;
  margin-right: 20px;
  font-weight: bold;
  &:hover {
    color: #b73232;
    transition: 0.3s;
  }
  @media (max-width: 900px) {
    font-size: 14px;
  }
`;
const EmailButton = styled.button`
  background: none;
  border: none;
  font-family: "syne", sans-serif;
  font-size: 22px;
  color: #222222;
  font-weight: bold;
  &:hover {
    color: #b73232;
    transition: 0.3s;
  }
  @media (max-width: 900px) {
    font-size: 14px;
    padding: 0;
  }
`;

export default ContactBar;
