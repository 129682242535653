import NavBar from "../NavBar";
import styled from "styled-components";
import ContactBar from "../ContactBar";
import SplitHeader from "../assets/Split/SplitHeader.png";
import SiteMap from "../assets/Split/SiteMap.png";
import SplitDesigns from "../assets/Split/Split-FinalDesign.png";
import Zoe from "../assets/Split/Zoe.png";
import Wireframes from "../assets/Split/Wireframes.png";
import SplitWiseApp from "../assets/Split/SplitwiseApp.png";
import SettleUpApp from "../assets/Split/SettleUpApp.png";
import SplitLogo from "../assets/Split/SplitLogo.png";
import ColorGroup from "../assets/Split/ColorGroup.png";
import RightGrotesk from "../assets/Split/RightGrotesk.png";
import Poppins from "../assets/Split/Poppins.png";

const Split = () => {
  return (
    <Wrapper>
      <WrapperHeader>
        <NavBar />
        <ImgWrapper>
          <HeaderImg src={SplitHeader} />
        </ImgWrapper>
      </WrapperHeader>
      <Section>
        <SubTitle>Overview</SubTitle>
        <DetailText>
          Split is an app that helps a groups of friends split the costs of
          various things such as activities, trips, and gifts. It helps keep
          track of spending and indicates how much is allocated to each person
          and if the cost is paid.
        </DetailText>
        <WrapperDetails>
          <Container>
            <SmallTitle>My Role</SmallTitle>
            <DetailText style={{ marginTop: "10px" }}>
              UI DESIGN, WEB DESIGN & PROTOTYPE
            </DetailText>
          </Container>
          <Container>
            <SmallTitle>Tools</SmallTitle>
            <DetailText style={{ marginTop: "10px" }}>
              FIGMA, ADOBE PHOTOSHOP & ADOBE ILLUSTRATOR
            </DetailText>
          </Container>
          <Container>
            <SmallTitle>Duration</SmallTitle>
            <DetailText style={{ marginTop: "10px" }}>
              MAY 2022 - JUNE 2022
            </DetailText>
          </Container>
        </WrapperDetails>
        <SubTitle>PROBLEM</SubTitle>
        <DetailText>
          As a college student, we do alot of group acitivities such as birthday
          events, traveling, and fun outings. A couple of people ususally ends
          up paying for items or tickets and there aren’t ways to keep track of
          how much we owe our friends. This sparked the question - why isn’t
          there a simple and clean app that can help with this to make the
          experience less complexe.
        </DetailText>
        <SubTitle>COMPETITION ANALYSIS</SubTitle>
        <SectionCompetition>
          <LeftCompetitition style={{ display: "flex" }}>
            <AppLogo src={SplitWiseApp} />
            <WrapperCompetition>
              <SmallTitle>SPLITWISE</SmallTitle>
              <DetailText style={{ marginTop: "10px" }}>
                Range of features is overwhelming and some feature were also
                uncessarry. Friends have to join the app to see the spread sheet
                of the payment.
              </DetailText>
            </WrapperCompetition>
          </LeftCompetitition>
          <RightCompetitition style={{ display: "flex" }}>
            <AppLogo src={SettleUpApp} />
            <WrapperCompetition>
              <SmallTitle>SETTLE UP</SmallTitle>
              <DetailText style={{ marginTop: "10px" }}>
                UX is confusing as well as the graphics that go along with the
                expenses.
              </DetailText>
            </WrapperCompetition>
          </RightCompetitition>
        </SectionCompetition>
        <DetailText style={{ marginTop: "0" }}>
          Despite each of the three apps taking a different approach to
          splitting bills , they failed to feel the UX simple and straight to
          the point. The designs as the features are overwhelming which will
          complicate the experience of the users. Dealing with money is already
          a headache, therefore there’s a market to create a simplified clean
          bill splitting app that will allow the experience to be seamless and
          pleasant.
        </DetailText>
      </Section>

      <SectionBlue>
        <Left>
          <SubTitle style={{ color: "white" }}>User Persona</SubTitle>
          <DetailText style={{ color: "white" }}>
            Meet Zoe! A 24 year old girl that loves to hang out with her
            friends. They love going out for dinners and clubbing. At times they
            travel and has a hard time keeping track of the groups spending.
            Sometimes receipts get lost and they lose track of their spendings.
            Now, she would take pictures of the receipts and calculate it
            manually after the trip. She tried using other spending apps but
            they were too complexe.
          </DetailText>
          <SmallTitle style={{ marginBottom: "10px", color: "white" }}>
            Pain Points
          </SmallTitle>
          <Li style={{ color: "white" }}>
            Inefficient ways to keep track of spendings
          </Li>
          <Li style={{ color: "white" }}>Losing receipt</Li>
          <Li style={{ color: "white" }}>
            Inefficient way of split costs since they divide costs evenly for
            each spendings instead of considering the total expense of the
            event.
          </Li>
          <SmallTitle
            style={{ marginBottom: "10px", marginTop: "50px", color: "white" }}
          >
            Goals
          </SmallTitle>
          <Li style={{ color: "white" }}>
            A tool that will help her keep track of the group expenses.{" "}
          </Li>
          <Li style={{ color: "white" }}>
            Simple and straightforward tool that requires only essential input.
          </Li>
          <Li style={{ color: "white" }}>
            An efficient way of splitting costs between the group.
          </Li>
        </Left>
        <Right>
          <Persona src={Zoe} />
        </Right>
      </SectionBlue>

      <Section>
        <SubTitle>Site Maps</SubTitle>
        <SitemapImg src={SiteMap} />
      </Section>

      <Section>
        <SubTitle>Wireframes</SubTitle>
        <WireframeWrapper>
          <FinalImg src={Wireframes} />
        </WireframeWrapper>
      </Section>
      <Section>
        <SubTitle>BRANDING / DESIGN SOLUTION</SubTitle>
        <ContainerRow>
          <ContainerLeft>
            <SmallTitle>Logo</SmallTitle>
            <LogoBranding src={SplitLogo} style={{ marginTop: "20px" }} />
          </ContainerLeft>
          <ContainerRight>
            <DetailText>
              Right Grotesk was chosen as the typographic logo. The way the bowl
              of the letter P enters the stem, it looks likes it’s splitting
              which is a fun element that mirrors the purpose of the app.
            </DetailText>
          </ContainerRight>
        </ContainerRow>
        <ContainerRow>
          <ContainerLeft>
            <SmallTitle>Color</SmallTitle>
            <LogoBranding src={ColorGroup} style={{ marginTop: "20px" }} />
          </ContainerLeft>
          <ContainerRight>
            <DetailText>
              The blue bright color was meticulously chosen as the main color of
              this app. Blue is known to evoques trustworthiness, dependable,
              fiscally responsible and secure. When dealing with fiances, these
              are what we want for the user to associate with our app.
            </DetailText>
          </ContainerRight>
        </ContainerRow>
        <ContainerRow style={{ border: "none" }}>
          <ContainerLeft>
            <SmallTitle>Typography</SmallTitle>
            <LogoBranding src={RightGrotesk} style={{ marginTop: "20px" }} />
          </ContainerLeft>
          <ContainerRight>
            <DetailText>
              Right Grotesk is chosen for this project as a header text and the
              typographic logo due to how it cuts in sharply into the stems of
              the letters which reflects splitting the expenses.
            </DetailText>
          </ContainerRight>
        </ContainerRow>
        <ContainerRow style={{ margin: "0", border: "none" }}>
          <ContainerLeft>
            <SmallTitle style={{ color: "transparent" }}> dsds</SmallTitle>
            <LogoBranding
              src={Poppins}
              style={{ marginTop: "20px", width: "180px" }}
            />
          </ContainerLeft>
          <ContainerRight>
            <DetailText>
              Poppins is seen as a rounded font and round shapes which evoque
              friendliness. It makes it easy on the eyes to read and the
              circularity makes it engaging and refreshing.
            </DetailText>
          </ContainerRight>
        </ContainerRow>
      </Section>
      <Section>
        <SubTitle>FINAL DESIGNS</SubTitle>
        <FinalImg src={SplitDesigns} />
      </Section>
      <LinkDiv>
        <Link
          href="https://www.figma.com/proto/AYu4iDnjQyN41xOUhSYYwH/Split?page-id=0%3A1&node-id=2%3A2&viewport=595%2C486%2C0.31&scaling=scale-down&starting-point-node-id=2%3A6"
          target="_blank"
        >
          CLICK HERE TO VIEW THE PROTOTYPE
        </Link>
      </LinkDiv>
      <WrapperLeftCorner>
        <ContactBar />
      </WrapperLeftCorner>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  margin: auto;
  padding-bottom: 50px;
`;

const WrapperHeader = styled.div``;

const Section = styled.div`
  margin-top: 100px;
  margin-right: 300px;
  margin-left: 300px;
  margin-bottom: 50px;
  /* mobile css */
  @media (max-width: 1300px) {
    margin-top: 50px;
    margin-right: 60px;
    margin-left: 60px;
    margin-bottom: 50px;
  }
  @media (max-width: 500px) {
    margin-top: 50px;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 50px;
  }
`;

const SectionBlue = styled.div`
  display: flex;
  padding-top: 100px;
  padding-right: 300px;
  padding-left: 300px;
  padding-bottom: 100px;
  background: #2f12e2;
  @media (max-width: 1300px) {
    flex-direction: column;
    padding-top: 50px;
    padding-right: 60px;
    padding-left: 60px;
    padding-bottom: 50px;
  }
  @media (max-width: 500px) {
    flex-direction: column;
    padding-bottom: 50px;
    padding-top: 50px;
    padding-right: 10px;
    padding-left: 10px;
  }
`;

const Left = styled.div`
  background: transparent;
  width: 50%;
  @media (max-width: 1300px) {
    width: 100%;
  }
`;
const SectionCompetition = styled.div`
  display: flex;
  padding-top: 50px;
  justify-content: space-between;
  @media (max-width: 1300px) {
    flex-direction: column;
    padding-top: 50px;
  }
  @media (max-width: 500px) {
    flex-direction: column;
    padding-top: 50px;
    padding-right: 10px;
    padding-left: 30px;
  }
`;
const RightCompetitition = styled.div`
  width: 50%;
  background: transparent;
  @media (max-width: 1300px) {
    width: 100%;
  }
`;

const LeftCompetitition = styled.div`
  width: 50%;
  margin-right: 20px;
  background: transparent;
  @media (max-width: 1300px) {
    width: 100%;
  }
`;
const Right = styled.div`
  display: flex;
  justify-content: center;
  background: transparent;
  width: 50%;
  margin-bottom: 100px;
  @media (max-width: 1300px) {
    width: 100%;
  }
`;

const HeaderImg = styled.img`
  width: 100%;
`;

const WrapperCompetition = styled.div`
  margin-left: 30px;
  @media (max-width: 1300px) {
    margin-left: 50px;
  }
  @media (max-width: 1300px) {
    margin-left: 10px;
  }
`;
const AppLogo = styled.img`
  height: 100px;
  width: auto;
`;
const Li = styled.li`
  font-family: "roc-grotesk", sans-serif;
  color: #222222;
  font-size: 23px;
  line-height: 1.4;
  background: transparent;
  /* mobile css */
  @media (max-width: 500px) {
    font-size: 16px;
    padding: 0 30px 0 30px;
  }
`;

const WireframeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media (max-width: 500px) {
    margin-top: 30px;
  }
`;
const ImgWrapper = styled.div`
  position: relative;
`;

const WrapperDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 350px;
`;

const DetailText = styled.p`
  margin-top: 30px;
  font-family: "roc-grotesk", sans-serif;
  color: #222222;
  font-size: 23px;
  line-height: 1.2;
  margin-bottom: 50px;
  background: transparent;
  line-height: 1.4;
  /* mobile css */
  @media (max-width: 500px) {
    margin-top: 20px;
    font-size: 16px;
    padding: 0 30px 0 30px;
    line-height: 1.4;
  }
`;

const Persona = styled.img`
  width: auto;
  height: 400px;
  border-radius: 10%;
  @media (max-width: 1300px) {
    margin-top: 50px;
    width: auto;
    height: 500px;
  }
  @media (max-width: 500px) {
    margin-top: 30px;
    width: auto;
    height: 300px;
  }
`;
const LinkDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Link = styled.a`
  font-family: "Syne", sans-serif;
  color: #222222;
  font-size: 32px;
  font-weight: bold;
  margin-top: 100px;
  margin-bottom: 100px;
  cursor: pointer;
  @media (max-width: 1300px) {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 80px;
    font-size: 20px;
  }
  @media (max-width: 500px) {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 80px;
    font-size: 20px;
  }
`;

const SubTitle = styled.h3`
  display: flex;
  align-items: center;
  font-family: "Syne", sans-serif;
  color: #222222;
  font-size: 32px;
  font-weight: bold;
  background: transparent;
  text-transform: uppercase;
  @media (max-width: 500px) {
    font-size: 22px;
    padding-left: 30px;
  }
`;

const SmallTitle = styled.h3`
  display: flex;
  align-items: center;
  font-family: "Syne", sans-serif;
  color: #222222;
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
  background: transparent;
  @media (max-width: 500px) {
    font-size: 22px;
    padding-left: 30px;
  }
`;

const WrapperLeftCorner = styled.div`
  display: flex;
  @media (max-width: 500px) {
    display: flex;
    padding-left: 30px;
    padding-top: 30px;
  }
`;
const SitemapImg = styled.img`
  width: 100%;
  margin-top: 50px;
  @media (max-width: 500px) {
    margin-left: 30px;
    width: 83%;
  }
`;
const FinalImg = styled.img`
  width: 100%;
  margin-top: 50px;
  @media (max-width: 500px) {
    margin-top: 20px;
    margin-left: 30px;
    width: 83%;
  }
`;
const ContainerRow = styled.div`
  display: flex;
  border-bottom: 1px solid #222222;
  margin-top: 50px;
  @media (max-width: 1300px) {
    flex-direction: column;
  }
`;

const ContainerLeft = styled.div`
  width: 50%;
  @media (max-width: 1300px) {
    width: 100%;
  }
`;
const ContainerRight = styled.div`
  margin-top: 20px;
  width: 50%;
  @media (max-width: 1300px) {
    width: 100%;
  }
`;
const LogoBranding = styled.img`
  margin-top: 20px;
  width: 300px;
  @media (max-width: 500px) {
    margin-left: 30px;
  }
`;
export default Split;
