import styled from "styled-components";
import Arrow from "./assets/ArrowLink.svg";
const IndividualProject = ({
  title,
  details,
  figma,
  github,
  id,
  imgProject,
  languages,
}) => {
  return (
    <Wrapper key={id}>
      <WrapperLeft>
        <SubTitle>{title}</SubTitle>
        <ImgProject src={imgProject} />
      </WrapperLeft>
      <WrapperRight>
        <DetailText>{details}</DetailText>
        <WrapperLink>
          <LinkDiv href={github} target="_blank">
            <ExternalLink>LEARN MORE ON GITHUB</ExternalLink>
            <ArrowLink src={Arrow} />
          </LinkDiv>
          {figma && (
            <LinkDiv href={figma} target="_blank">
              <ExternalLink>VIEW DESIGN ON FIGMA</ExternalLink>
              <ArrowLink src={Arrow} />
            </LinkDiv>
          )}
        </WrapperLink>
        <LanguageWrapper>
          {languages.map((language) => {
            return <LanguageBox key={id}>{language}</LanguageBox>;
          })}
        </LanguageWrapper>
      </WrapperRight>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  border-bottom: solid 1px #222222;
  padding-bottom: 70px;
  padding-top: 70px;

  /* mobile css */
  @media (max-width: 900px) {
    flex-direction: column;
    padding-top: 30px;
  }
`;
const WrapperLeft = styled.div`
  /* mobile css */
  display: flex;
  flex-direction: column;
  margin: 0 30px 0 30px;
  align-items: center;

  /* desktop css */
  @media (min-width: 900px) {
    padding-left: 50px;
    padding-right: 50px;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
  }
`;
const WrapperRight = styled.div`
  /* mobile css */
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  /* desktop css */
  @media (min-width: 900px) {
    padding-left: 50px;
    padding-right: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
  }
`;
const SubTitle = styled.h3`
  font-family: "Syne", sans-serif;
  color: #222222;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 30px;
  text-transform: uppercase;

  /* mobile css */
  @media (max-width: 900px) {
    flex-direction: column;
    font-size: 20px;
  }
`;
const ImgProject = styled.img`
  width: 550px;
  border-radius: 10%;
  border: 0.5px solid #222222;
  /* mobile css */
  @media (max-width: 900px) {
    width: 100%;
  }
`;

const DetailText = styled.p`
  font-family: "roc-grotesk", sans-serif;
  color: #222222;
  font-size: 23px;
  line-height: 1.2;
  margin-bottom: 50px;
  /* mobile css */
  @media (max-width: 900px) {
    margin-top: 30px;
    font-size: 16px;
    padding: 0 30px 0 30px;
  }
`;
const ExternalLink = styled.p`
  font-family: "roc-grotesk", sans-serif;
  font-size: 23px;
  text-transform: uppercase;
  color: #222222;
  @media (max-width: 900px) {
    font-size: 16px;
    padding: 0 30px 0 30px;
  }
`;

const WrapperLink = styled.div`
  display: flex;
  flex-direction: column;
`;

const LinkDiv = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-top: 10px;
  transition: 0.5s;
  &:hover {
    margin-left: 15px;
    transition: 0.5s;
  }
`;
const ArrowLink = styled.img`
  margin-left: 20px;
  width: 28px;
  &:hover {
    color: #b73232;
  }
`;
const LanguageWrapper = styled.div`
  display: flex;
  margin-top: 50px;
  @media (max-width: 900px) {
    overflow-x: auto;
    padding-left: 30px;
    padding-right: 30px;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`;
const LanguageBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px #222222;
  border-radius: 25px;
  font-family: "roc-grotesk", sans-serif;
  text-transform: uppercase;
  font-size: 25px;
  padding: 10px 30px 10px 30px;
  margin-right: 50px;
  /* mobile css */
  @media (max-width: 900px) {
    font-size: 16px;
    padding: 5px 15px 5px 15px;
    margin: 0;
    margin-right: 20px;
  }
`;
export default IndividualProject;
