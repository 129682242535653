import NavBar from "../NavBar";
import styled from "styled-components";
import ContactBar from "../ContactBar";
import MackageHeader from "../assets/Mackage/MackageHeader.png";
import Homepage from "../assets/Mackage/Homepage.png";
const Mackage = () => {
  return (
    <Wrapper>
      <WrapperHeader>
        <NavBar />
        <ImgWrapper>
          <HeaderImg src={MackageHeader} />
        </ImgWrapper>
      </WrapperHeader>
      <Section>
        <SubTitle>Homepage Designs</SubTitle>
        <Img src={Homepage} />
      </Section>
      <WrapperLeftCorner>
        <ContactBar />
      </WrapperLeftCorner>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  margin: auto;
  padding-bottom: 50px;
`;

const WrapperHeader = styled.div``;
const ImgWrapper = styled.div`
  position: relative;
`;
const HeaderImg = styled.img`
  width: 100%;
`;
const SubTitle = styled.h3`
  display: flex;
  align-items: center;
  font-family: "Syne", sans-serif;
  color: #222222;
  font-size: 32px;
  font-weight: bold;
  background: transparent;
  text-transform: uppercase;
  @media (max-width: 500px) {
    font-size: 22px;
    padding-left: 30px;
  }
`;
const Section = styled.div`
  margin-top: 100px;
  margin-right: 300px;
  margin-left: 300px;
  margin-bottom: 50px;
  /* mobile css */
  @media (max-width: 1300px) {
    margin-top: 50px;
    margin-right: 60px;
    margin-left: 60px;
    margin-bottom: 50px;
  }
  @media (max-width: 500px) {
    margin-top: 50px;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 50px;
  }
`;
const Img = styled.img`
  margin-top: 50px;
  width: 100%;
  @media (max-width: 500px) {
    margin-left: 30px;
    width: 85%;
  }
`;

const WrapperLeftCorner = styled.div`
  display: flex;
  @media (max-width: 500px) {
    display: flex;
    padding-left: 30px;
    padding-top: 30px;
  }
`;
export default Mackage;
